<template>
	<div>
		<ArticlesComponent :articles='articles' :loading='loading'></ArticlesComponent>
	</div>
</template>

<script type="text/javascript">

import ArticlesComponent from "@/components/ArticlesComponent_v2.vue"

export default {
	name: 'Articles',
	components: {
		ArticlesComponent,
	},
	computed: {
		articles() { console.log(this.$store.getters.getArticles); return this.$store.getters.getArticles.filter(item=>item.post==true) },
		loading()  { return this.$store.getters.getArticlesLoading },
	},
	beforeMount(){
		//this.getArticles()
		this.$store.dispatch('uploadArticlesAsync')
		
	}
}
</script>