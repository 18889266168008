<template>
	<div>

		<v-skeleton-loader type="card, list-item-two-line" v-if="loading" ></v-skeleton-loader>
		<v-row>
			<v-col class="col-12 pb-1" v-for="article in articles" :key="article.id" >
				<v-card :to="getRoute(article.id)">
					<v-card-text>
						<span v-text="article.title" class="text-md-h5"></span>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script type="text/javascript">

export default {
	name: 'Articles',
	props: ['articles', 'loading',],
	
	data () {
		return {}
	},
	methods: {
		getRoute(id){
			return "/post/"+id
		}
	},
}
</script>
